<template>
  <div class="info">
    <div class="code">
      <img :src="code" alt="" />
      <!-- <div class="new">
        <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675323147022ce.png" alt="" />
        <span>刷新</span>
      </div> -->
      <div class="website">二级网站域名：{{ viewUrl }}</div>
    </div>
    <div class="list">
      <div class="title">
        <span class="left">域名列表:(已最近审核成功的域名为主域名)</span>
        <span class="right" @click="$refs.formDialog.formVisible = true">绑定独立域名</span>
      </div>
      <div class="data">
        <el-table :data="list" @sort-change="changeTableSort">
          <el-table-column prop="domain" label="域名" align="center">
            <div slot-scope="scope" class="name">
              <span>{{ scope.row.domain }}</span>
              <span class="main" v-if="scope.row.state == 2">[主域名]</span>
            </div>
          </el-table-column>
          <el-table-column prop="use" label="启用" align="center" width="80px">
            <div slot-scope="scope" class="icon">
              <i v-if="scope.row.state == 2" class="el-icon-check"></i>
              <i v-if="scope.row.state == 3" class="el-icon-close"></i>
            </div>
          </el-table-column>
          <el-table-column prop="state" label="状态" align="center">
            <div slot-scope="scope" :class="scope.row.state == 1 ? 'http' : ''">
              <span>{{ scope.row.state == 1 ? '待审核' : scope.row.state == 2 ? '已生效' : '审核拒绝' }}</span>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <domain-manage ref="formDialog" :list="list" @refreshData="loadList"></domain-manage>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import domainManage from './domain-manage'

export default {
  components: {
    domainManage
  },
  data() {
    return {
      viewUrl: '',
      code: '',
      list: [],
      project: localStorage.getItem('mall-project')
    }
  },
  mounted() {
    this.getText()
    this.getList()
  },
  methods: {
    loadList() {
      this.getList()
    },
    async getList() {
      const res = await http.POST('/jw-admin', {
        module: 'settings',
        operation: 'getDomainUserList',
        project: JSON.parse(this.project)._id
      })
      this.list = res.data
    },
    getQr(id) {
      let options = {
        padding: 0, // 二维码四边空白（默认为10px）
        width: 180, // 二维码图片宽度（默认为256px）
        height: 180, // 二维码图片高度（默认为256px）
        correctLevel: QRErrorCorrectLevel.H, // 二维码容错level（默认为高）
        reverse: false, // 反色二维码，二维码颜色为上层容器的背景颜色
        background: '#ffffff', // 二维码背景颜色（默认白色）
        foreground: '#000000' // 二维码颜色（默认黑色）
      }
      this.code = jrQrcode.getQrBase64(this.viewUrl, options)
    },
    getText() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem',
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          if (res) {
            this.viewUrl = res.viewUrl + '?projectId=' + JSON.parse(this.project)._id
            this.getQr()
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  background: #f6f9fc;
  padding: 30px 20px;
  .code {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      width: 85px;
      height: 85px;
      margin-top: 97px;
    }
    .new {
      display: flex;
      align-items: center;
      margin-top: 20px;
      > img {
        width: 13px;
        height: 12px;
        margin-right: 3px;
      }
      > span {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #336dff;
        line-height: 17px;
      }
    }
    .website {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #666666;
      line-height: 17px;
      margin-top: 28px;
    }
  }
  .list {
    width: 100%;
    padding: 50px;
    .title {
      display: flex;
      align-items: center;
      margin-top: 31px;
      padding: 2px 15px 7px 0;
      .left {
        flex: 1;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }
      .right {
        width: 130px;
        height: 41px;
        background: #008aff;
        border-radius: 5px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .data {
      margin-top: 20px;
      ::v-deep .el-table__cell {
        border: none;
      }
      .el-table::before {
        display: none;
      }
      ::v-deep .el-table__cell {
        padding: 22px 0;
        background: #f6f9fc;
      }
      ::v-deep thead {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
      }
      ::v-deep .el-table__row {
        &:nth-child(2n-1) {
          .el-table__cell {
            background-color: #fff;
          }
        }
      }
      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        .main {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #336dff;
          line-height: 20px;
          margin-left: 5px;
        }
      }
      .icon {
        .el-icon-check {
          size: 16px;
          color: #fc103f;
        }
        .el-icon-close {
          size: 16px;
          color: #4cbc26;
        }
      }
      .http {
        color: #56c22d;
      }
    }
  }
}
</style>
