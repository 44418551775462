<template>
  <el-dialog class="dialog" width="80%" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
    <div class="box">
      <div class="steps">
        <el-steps :active="active" align-center>
          <el-step title="添加TxT"></el-step>
          <el-step title="添加指向值"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
      </div>
      <div class="step">
        <div class="tit">1.请复制记录值，在第三方域名提供商中，进行配置DNS添加记录类型到主机记录中：</div>
        <div class="list">
          <el-table :data="list" @sort-change="changeTableSort">
            <el-table-column prop="mine" label="主机记录" align="center"></el-table-column>
            <el-table-column prop="type" label="记录类型" align="center"></el-table-column>
            <el-table-column prop="value" label="记录值" align="center">
              <div slot-scope="scope" class="value">
                <span>{{ scope.row.value }}</span>
                <!-- <span class="copy">复制</span> -->
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="two-step">
        <div class="tit">2.请配置好的DNS域名进行下一步绑定：</div>
        <div class="cont">
          <div class="txt">
            <input v-model="domain" placeholder="请输入绑定的域名地址 例：www.baidu.com(不要加https://)" />
          </div>
          <!-- <div class="button">检测并绑定</div> -->
        </div>
      </div>
      <div class="submit" @click="add">
        <span>提交绑定审核</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud'

export default {
  data() {
    return {
      formVisible: false, //表单显示状态
      active: 1,
      list: [
        {
          mine: '你自己定义',
          type: 'CNAME',
          value: ''
        }
      ],
      domain: ''
    }
  },
  mounted() {
    this.getText()
  },
  methods: {
    async add() {
      if (this.domain == '') {
        this.$message({
          showClose: true,
          message: '请输入域名',
          type: 'warning'
        })
        return
      }
      const project = localStorage.getItem('mall-project')
      const id = JSON.parse(project)._id
      const res = await http.POST('/jw-admin', {
        module: 'settings',
        operation: 'addDomain',
        domain: this.domain,
        project: id,
        uid: localStorage.getItem('uid')
      })
      if (res.status == 1) {
        this.$message({
          showClose: true,
          message: '提交成功',
          type: 'success'
        })
        this.formVisible = false
      }
    },
    next() {
      if (this.active++ > 2) this.active = 0
    },
    getText() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem'
        })
        .then(res => {
          if (res) {
            this.list[0].value = res.viewUrl
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  .box {
    padding: 0 50px;
    .steps {
      ::v-deep .el-step {
        .is-finish {
          .el-step__line {
            border-bottom: 2px dashed #008aff;
            .el-step__line-inner {
              border-bottom: 2px dashed rgba(0, 0, 0, 0);
            }
          }
          .el-step__icon {
            width: 45px;
            height: 45px;
            border: none;
            background: #008aff;
            color: #fff;
          }
          .el-step__title {
            color: #333333;
          }
        }
        .el-step__title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .is-process {
          .el-step__icon {
            color: #008aff;
            border: 3px solid #008aff;
          }
        }
        .el-step__line {
          top: 22px;
          background-color: #dddddd;
        }
        .el-step__icon {
          width: 45px;
          height: 45px;
          border: 2px solid #dddddd;
          font-size: 26px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #dddddd;
        }
      }
    }
    .step {
      margin-top: 120px;
      .tit {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-bottom: 19px;
      }
      .list {
        padding: 0 10px;
        .el-table::before {
          background-color: #dae5ff;
        }
        ::v-deep .el-table__cell {
          padding: 23px 0 22px;
          border-color: #dae5ff;
        }
        ::v-deep thead {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 20px;
          .el-table__cell {
            padding: 24px 0 21px;
            background: #f6f9fc;
          }
        }
        .value {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .copy {
          width: 97px;
          height: 34px;
          background: #ecf6ff;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          cursor: pointer;
        }
      }
    }
    .two-step {
      margin-top: 40px;
      .tit {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-bottom: 19px;
      }
      .cont {
        display: flex;
        .txt {
          display: flex;
          align-items: flex-end;
          span {
            display: block;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
            margin: 0 8px;
          }
          input {
            padding: 0 20px;
            &:nth-of-type(1) {
              width: 500px;
              height: 40px;
              border-radius: 5px;
              border: 1px solid #dae5ff;
            }
            &:nth-of-type(2) {
              width: 500px;
              height: 40px;
              border-radius: 5px;
              border: 1px solid #dae5ff;
            }
          }
        }
        .button {
          height: 40px;
          background: #ecf6ff;
          border-radius: 5px;
          border: 1px solid #dae5ff;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          padding: 0 25px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .submit {
      display: flex;
      justify-content: center;
      margin: 200px 0 100px;
      span {
        width: 300px;
        height: 45px;
        background: #ecf6ff;
        border-radius: 5px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
</style>
